<template>
    <div>
        <b-button variant="success"
                  @click="$router.push({name: 'DegiskenKelimeEkleVeyaDuzenle'})"
                  class="mb-3" style="float: right; padding: 10px;">
            <b-icon icon="plus"></b-icon>
            Degişken Kelime Ekle
        </b-button>

        <div style="clear:both;"></div>
        <b-table stacked="sm"
                 :busy="isBusy"
                 :items="items"
                 :fields="fields">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Yükleniyor...</strong>
                </div>
            </template>
            <template v-slot:cell(ad)="data">
                { {{data.item.ad}} }
            </template>
            <template v-slot:cell(islemler)="data">
                <b-button variant="info"
                          @click="duzenle(data.item.id)">
                    <b-icon icon="pencil"></b-icon>
                </b-button>
                <b-button class="ml-3" variant="danger"
                          @click="sil(data.item.id)">
                    <b-icon icon="x-circle"></b-icon>
                </b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
    import {HepsiniGetir} from "@/api/dkelime"
    import {Sil} from "@/api/dkelime"
    import {BasariliMesajiniGoster} from "@/messages"

    export default {
        name: "degiskenkelimeler",
        data() {
            return {
                isBusy: true,
                fields: [
                    {key: 'ad', label: 'Ad'},
                    {key: 'degerler', label: 'Degerler'},
                    {key: 'islemler', label: 'İşlemler'}
                ],
                items: []
            }
        },
        methods: {
            duzenle(dkId) {
                this.$router.push({name: 'DegiskenKelimeEkleVeyaDuzenle', params: {dkId}})
            },
            async sil(dkId) {
                const {message} = await Sil(dkId)
                BasariliMesajiniGoster(message)
                this.kelimeleriGetir()
            },
            async kelimeleriGetir() {
                this.isBusy = true;
                const {data} = await HepsiniGetir()
                this.items = data;
                this.isBusy = false;
            }
        },
        async created() {
            await this.kelimeleriGetir()
        }
    }
</script>

<style scoped>

</style>
