import http from '@/request'

export const Getir = (dkId) => {
    return http.request({
        url: 'DKelime/Getir',
        params: {
            dkId
        }
    })
}

export const HepsininAdlariniGetir = () => {
    return http.request({
        url: 'DKelime/HepsininAdlariniGetir'
    })
}

export const HepsiniGetir = () => {
    return http.request({
        url: 'DKelime/HepsiniGetir'
    })
}

export const EkleVeyaDuzenle = (id, key, values) => {
    return http.request({
        url: 'DKelime/EkleVeyaDuzenle',
        method: 'post',
        params: {
            id,
            key,
            values
        }
    })
}

export const Sil = (dkId) => {
    return http.request({
        url: 'DKelime/Sil',
        method: 'delete',
        params: {
            dkId
        }
    })
}
