import Vue from 'vue'

export const BasariliMesajiniGoster = (mesaj) => {
    Vue.$toast.open({
        message: mesaj,
        type: 'success',
        position: 'top',
        duration: 4000
    });
}

export const HataliMesajiniGoster = (mesaj) => {
    Vue.$toast.open({
        message: mesaj,
        type: 'error',
        position: 'top',
        duration: 4000
    })
}
